
<template>
    <div class="content submitExam">
        <div class="submitExam-tit">当前位置：模拟考试</div>
        <div class="submitExam-con">
            <div class="student-info">
                <div class="student-info-img">
                    <img :src="user.HeadImageUrl || require('../../images/avatar.png')" />
                </div>
                <p class="student-info-name">{{ user.Name || user.Phone }}</p>
            </div>
            <div class="student-score">
                <div
                    class="student-score-img"
                    v-if="img"
                >
                    <img :src="img" />
                </div>
                <div
                    style="text-align:center;font-size: 20px;"
                    :style="{ color : score >=90 ? '#67c23a':'#f56c6c'}"
                >
                    {{title}}
                </div>
                <div class="student-score-txt">
                    <span>
                        得分：
                        <font color="#ff470f">{{score}}</font>
                    </span>
                    <span>
                        用时：
                        <font color="#34a2ff">{{time}}</font>
                    </span>
                </div>
                <div class="student-score-btn">
                    <div
                        class="score-btn01"
                        @click="goWrong"
                    >错题重做</div>
                    <div
                        class="score-btn02"
                        style="margin:0 auto;"
                        @click="$router.push('/choiceCar')"
                    >返回</div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
@import '../../styles/public.scss';
@import 'submitExam.scss';
</style>
<script>
export default {
    data() {
        return {
            time: 0,
            score: 0,
            user: {},
            img: ''
        };
    },
    computed: {
        title() {
            if (this.score >= 90) {
                return '恭喜过关！';
            }
            return '马路杀手！';
        }
    },
    created() {
        var params = this.$route.params;
        if (params.min) {
            this.time = params.min;
            this.score = params.score;
        } else {
            this.$router.push({ name: 'choice_car' });
        }
        this.user = this.$util.getUser();
        this.getImg();
    },
    methods: {
        getImg() {
            this.$api.getExamImg({ source: this.score }).then(res => {
                this.img = res.data;
            });
        },
        goWrong() {
            this.$router.push({
                path: '/examExercises?id=-1&type=1',
                query: {
                    id: -1,
                    type: Number(this.$route.query.type)
                }
            });
        }
    }
};
</script>
